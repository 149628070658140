import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import Image from 'components/image';
import SEO from 'components/seo';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`The Sunday Mailbag + A Special Guest Coming This Week!`}</h1>
    <p><strong parentName="p">{`Building a `}{`$`}{`1M+ Course Business`}</strong></p>
    <p>{`When i started thinking about how to build a `}{`$`}{`1m+ course business, i went to
look at the playbook of others.`}</p>
    <p>{`As you probably know by now, i’m all about dissecting how others are getting it
done and figuring out the remix to their playbooks`}</p>
    <p>{`That’s how i found Jack Butcher (here’s
`}<a parentName="p" {...{
        "href": "https://click.convertkit-mail2.com/75ulv2g0gwa8hq9xd4tz/qvh8h7h8gzg5n0ul/aHR0cHM6Ly90d2l0dGVyLmNvbS9qYWNrYnV0Y2hlcg=="
      }}>{`his twitter`}</a>{`,
i bet you’ve seen it before)`}</p>
    <p>{`He basically went from $0 → $1m+ in 18 months.`}</p>
    <p>{`He teaches people how to use design to simplify concepts and make your point in
the simplest way possible.`}</p>
    <p>{`Looking at what he’s doing you can clearly see the groundwork he’s laying for
that flywheel you know i love:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "445px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "71.25%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAOABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAgAF/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECBP/aAAwDAQACEAMQAAAB1izedxhf/8QAFxABAQEBAAAAAAAAAAAAAAAAABEBEP/aAAgBAQABBQLV7Ff/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAY/Al//xAAbEAACAgMBAAAAAAAAAAAAAAABEQAhMVFhQf/aAAgBAQABPyEiwl2YsXyMbhDIMqKrcDeT/9oADAMBAAIAAwAAABDE/wD/xAAXEQADAQAAAAAAAAAAAAAAAAABEBEh/9oACAEDAQE/EBJq/8QAFREBAQAAAAAAAAAAAAAAAAAAECH/2gAIAQIBAT8Qp//EAB0QAQACAgIDAAAAAAAAAAAAAAEAETFRIWFBkdH/2gAIAQEAAT8QAFBlOvsvZRmaUp1zOp7jiOBEcJGzh05U1fiGbKT/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Solopreneur Flywheel",
          "title": "Solopreneur Flywheel",
          "src": "/static/08408ee85ce9ff984f49ac8a9425fc05/3e119/solopreneur-flywheel.jpg",
          "srcSet": ["/static/08408ee85ce9ff984f49ac8a9425fc05/a6407/solopreneur-flywheel.jpg 400w", "/static/08408ee85ce9ff984f49ac8a9425fc05/3e119/solopreneur-flywheel.jpg 445w"],
          "sizes": "(max-width: 445px) 100vw, 445px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><strong parentName="p">{`And I’m gonna host him this Friday (11/20) for a live Zoom AMA with y'all at
3pm EST. I’ll ask him a few questions, but you should come prepared with your
own questions too!`}</strong></p>
    <p><del parentName="p"><a parentName="del" {...{
          "href": "https://click.convertkit-mail2.com/75ulv2g0gwa8hq9xd4tz/g3hnh5he7r75pztr/aHR0cHM6Ly9sdS5tYS91N2xyZWwzMg=="
        }}>{`Click here to RSVP now.`}</a></del>{`
And if you can't make it, don't worry, we'll send out a recording and notes for
you!`}</p>
    <p><strong parentName="p">{`The Sunday Mailbag`}</strong></p>
    <p><strong parentName="p">{`E-Commerce`}</strong></p>
    <p><strong parentName="p">{`Who in your opinion, are three entrepreneurs that we should all be following
in the e-commerce space? Looking for people who share their knowledge and
experiences.`}</strong></p>
    <p>{`When it comes to people who share ecom knowledge publicly….i''ll give you 4:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://click.convertkit-mail2.com/75ulv2g0gwa8hq9xd4tz/9qhzhnhgk0km9gh9/aHR0cHM6Ly90d2l0dGVyLmNvbS9tcnNoYXJtYQ=="
        }}>{`Nik Sharma`}</a>{` -
does a good newsletter on d2c tactics`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://click.convertkit-mail2.com/75ulv2g0gwa8hq9xd4tz/3ohphkhq606lz7sr/aHR0cHM6Ly90d2l0dGVyLmNvbS9tb2l6YWxp"
        }}>{`Moiz Ali`}</a>{`
(Native Deodorant) - checkout his podcast Exit Strategy`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://click.convertkit-mail2.com/75ulv2g0gwa8hq9xd4tz/n2hohvhnwmwprvt6/aHR0cHM6Ly90d2l0dGVyLmNvbS9kZW5uaXNoZWdzdGFk"
        }}>{`Dennis Hegstad`}</a>{` -
he runs a sms abandoned cart service called Live Recover`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://click.convertkit-mail2.com/75ulv2g0gwa8hq9xd4tz/48hvheh0zvzeg6ux/aHR0cHM6Ly90d2l0dGVyLmNvbS9UYXlsb3JTaWNhcmQ="
        }}>{`Taylor Sicard`}</a>{` -
founder of homesick candles, a quiet giant in ecomm`}</li>
    </ul>
    <p><strong parentName="p">{`If working on an e-commerce product, and you have an LLC, when do you look at
getting general liability insurance? I have an ingestible product manufactured
elsewhere, but I worry about getting sued. On the other hand, insurance can be
quite an expense upfront when you are not certain of gaining any traction. Do
you think the LLC in itself provides adequate protection in the short run?`}</strong></p>
    <p>{`I’m not a lawyer, so I won’t tell you what to do. But I’ll tell you what my wife
did, she got insurance after the first month or so of testing the concept, just
to play it safe. There’s this principle called the Kelly Criterion. It’s a fancy
way of saying: “avoid total ruin”. Like, don’t bet everything on one roll of the
dice. So I think insurance is worth the cost. She got a “BOP” (Business Owner’s
Policy”) that covers quite a bit.`}</p>
    <p><strong parentName="p">{`Should we use Kickstarter of Indiegogo for a product launch?`}</strong></p>
    <p>{`Would need more context to give a more nuanced answer. In short, it’s all the
same. Just start selling.`}</p>
    <p><strong parentName="p">{`My Frameworks & Principles:`}</strong></p>
    <p><strong parentName="p">{`On the podcast and on the AMAs, you often mention books that have helped you
both personally and business wise. Do you have a list of your favourite books
stored somewhere? If so, would you be willing to share with the group at some
stage? If not, what are three of your favourite books?What is the best
framework/exercise to cultivate bias-to-action?`}</strong></p>
    <p>{`I’ve had my deepest learnings come from “teachers” rather than books. My top 4
teachers are:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Tony Robbins -`}</strong>{` I attended an Unleash the Power Within event. It’s 4 days,
and I got a `}{`$`}{`500 ticket on black friday. Some people can’t stand the guy, but
I thought it was amazing.`}</li>
      <li parentName="ul"><strong parentName="li">{`My Sushi Chef -`}</strong>{` I opened a sushi restaurant and partnered with a chef I
saw on Food Network. I flew to LA to live with him and learn from him..I
learned a bit about sushi, and a lot about life (he is a one of a kind type of
person). Very wise, has his own life philosophy, and taught me a bit about
buddhism.`}</li>
      <li parentName="ul"><strong parentName="li">{`Naval -`}</strong>{` go listen to every podcast of his, twice. Great nuggets of wisdom
about business & life.`}</li>
      <li parentName="ul"><strong parentName="li">{`My current personal trainer -`}</strong>{` he trains me on my body but also my mind to
make it more resilient and help me overcome common traps we fall into.`}</li>
    </ul>
    <p>{`Sorry that’s not as helpful as pointing to a few books. But maybe helpful in its
own way. Go find teachers to learn from (outside of school). I myself hope to be
that teacher for people over the next 10-20 years.`}</p>
    <p><strong parentName="p">{`Any advice/frameworks on when to think about quitting a full-time job and
starting your own business? Some people say you should quit when your side
hustle matches your salary. However, in my situation, I work around 60-80hrs a
week in investment banking which leaves very little time to build a business on
the side.`}</strong></p>
    <p>{`Quit yesterday.`}</p>
    <p>{`Unless you have big financial obligations (eg. kids, wife, mortgage), then
reduce your financial burn and/or save up 2 years of runway then quit. You won’t
regret quitting, but you probably will regret waiting to quit. It’s very rare
that people regret giving their dreams a chance.`}</p>
    <p><strong parentName="p">{`Random Musings:`}</strong></p>
    <p><strong parentName="p">{`I liked your`}</strong>{`
`}<a parentName="p" {...{
        "href": "https://click.convertkit-mail2.com/75ulv2g0gwa8hq9xd4tz/reh8hohq949rxnu2/aHR0cHM6Ly90d2l0dGVyLmNvbS9TaGFhblZQL3N0YXR1cy8xMjc0MzY5NTk5NzAyNjE0MDE2"
      }}>{`tweetstorm`}</a>{`
`}<strong parentName="p">{`on the issues with restaurant businesses. Do you think all these flaws in the
restaurant business model have been addressed with cloud kitchens?`}</strong></p>
    <p>{`Not really. I basically said:`}</p>
    <ul>
      <li parentName="ul">{`Crappy margins (~10%)`}</li>
      <li parentName="ul">{`Crappy lifestyle (7 days a week)`}</li>
      <li parentName="ul">{`Small size of prize (even a successful restaurant won’t make you a
multi-millionaire)`}</li>
    </ul>
    <p>{`Those are all still true for cloud kitchens, except the size of the prize. I
think someone will create the next Dominos or Pinkberry as a cloud kitchen. It’s
still a grueling, low margin biz. There are many easier ways to win.`}</p>
    <p>{`-`}{` shaan`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      